import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

export default function UIEvaluationPlanTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath}>
      <h2 className="title is-2">Formative Evaluations</h2>
      <table>
        <thead>
          <tr>
            <th rowspan="2">ID</th>
            <th rowspan="2">When to test</th>
            <th rowspan="2">Users / User Profile</th>
            <th rowspan="2">Evaluation Method</th>
            <th rowspan="2">Interface Element</th>
            <th rowspan="2">Documentation/Training Provided</th>
            <th rowspan="2">Test Environment</th>
            <th rowspan="2">Method of collecting data</th>
            <th colspan="4">Results Documentation</th>
          </tr>
          <tr>
            <th>Document ID</th>
            <th>Section</th>
            <th>Revision</th>
          </tr>
        </thead>
        {frontmatter.formativeEvaluationsTable.map((row) => (
          <tr>
            <td>{row.ID}</td>
            <td>{row.whenToTest}</td>
            <td>{row.userProfile}</td>
            <td>{row.evaluationMethod}</td>
            <td>{row.interfaceElement}</td>
            <td>{row.trainingDocumentationProvided}</td>
            <td>{row.testEnvironment}</td>
            <td>{row.dataCollectionMethod}</td>
            <td>{row.resultsDocumentation.documentLink}</td>
            <td>{row.resultsDocumentation.section}</td>
            <td>{row.resultsDocumentation.revision}</td>
          </tr>
        ))}
      </table>

      <h2 className="title is-2">Summative Evaluations</h2>
      <table>
        <thead>
          <tr>
            <th rowspan="2">ID</th>
            <th rowspan="2">Use Scenario</th>
            <th rowspan="2">Users / User Profile</th>
            <th rowspan="2">Evaluation Method</th>
            <th rowspan="2">Interface Element</th>
            <th rowspan="2">Documentation/Training Provided</th>
            <th rowspan="2">Test Environment</th>
            <th rowspan="2">Method of collecting data</th>
            <th colspan="4">Results Documentation</th>
          </tr>
          <tr>
            <th>Document ID</th>
            <th>Section</th>
            <th>Revision</th>
          </tr>
        </thead>
        {frontmatter.summativeEvaluationsTable.map((row) => (
          <tr>
            <td>{row.ID}</td>
            <td>{row.useScenario}</td>
            <td>{row.userProfile}</td>
            <td>{row.evaluationMethod}</td>
            <td>{row.interfaceElement}</td>
            <td>{row.trainingDocumentationProvided}</td>
            <td>{row.testEnvironment}</td>
            <td>{row.dataCollectionMethod}</td>
            <td>{row.resultsDocumentation.documentLink}</td>
            <td>{row.resultsDocumentation.section}</td>
            <td>{row.resultsDocumentation.revision}</td>
          </tr>
        ))}
      </table>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryUIEval($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        formativeEvaluationsTable {
          ID
          whenToTest
          userProfile
          evaluationMethod
          interfaceElement
          trainingDocumentationProvided
          testEnvironment
          dataCollectionMethod
          resultsDocumentation {
            documentLink
            section
            revision
          }
        }
        summativeEvaluationsTable {
          ID
          useScenario
          userProfile
          evaluationMethod
          interfaceElement
          trainingDocumentationProvided
          testEnvironment
          dataCollectionMethod
          resultsDocumentation {
            documentLink
            section
            revision
          }
        }
      }
    }
  }
`
